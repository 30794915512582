<template>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <h4>연구실 신청 리스트</h4>
      </nav>
      <div class="container-fluid">
        <h3>연구실 신청 리스트를 표시한다</h3>
        <h3>추후 개발</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: { },
  data() {
    return {
      zip: '',
      addr1: '',
      addr2: ''
    }
  },

  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {

  }
}
</script>

<style scoped>
  /* table > tbody > tr > td { text-align: left; width: 300px;}
  table > tbody > tr > td > input { width: 100%; } */
</style>
